import {
  Scene,
  StereoCamera,
  PlaneGeometry,
  VideoTexture,
  MeshBasicMaterial,
  Mesh,
  Vector2,
  SRGBColorSpace,
  EquirectangularReflectionMapping,
  SphereGeometry,
  BackSide,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { getPerspectiveCamera, getWebGLRenderer } from "./viewHelper";

export default class View3d {
  constructor() {
    this.scene = null;
    this.renderer = null;
    this.screen = null;
    this.defaultCamera = null;
    this.stereoCamera = null;
    this.orbitControl = null;
    this.environmentSphere = null;
  }

  init() {
    if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
      const video = document.createElement("video");
      video.id = "srcVideo";
      video.setAttribute("crossorigin", "anonymous");
      video.hidden = false;
      video.setAttribute("playsInline", "true");
      video.setAttribute("muted", "true");
      video.controls = false;
      video.autoplay = true;
      document.getElementById("container").appendChild(video);
    }

    this.renderer = getWebGLRenderer(false);

    this.scene = new Scene();
    this.defaultCamera = getPerspectiveCamera();

    this.stereoCamera = new StereoCamera();
    this.stereoCamera.aspect = 0.5;
    this.stereoCamera.eyeSep = 1;

    this.orbitControl = new OrbitControls(
      this.defaultCamera,
      this.renderer.domElement
    );
    // zoom
    this.orbitControl.minDistance = Math.PI / 2;
    this.orbitControl.maxDistance = Math.PI;
    this.orbitControl.update();
    // Disable camera pan by swiping on screen
    this.orbitControl.enabled = false;
  }

  renderScreen({ stream }) {
    const screenGeometry = new PlaneGeometry(16, 9);

    let videoTexture = null;
    if (stream) {
      let srcVideo = null;
      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        srcVideo = document.getElementById("srcVideo");
        srcVideo.srcObject = stream;
      } else {
        const videoTrackId = stream.videoTrack._ID;
        stream.videoTrack.play("container");
        srcVideo = document.getElementById("video_" + videoTrackId);
      }
      videoTexture = new VideoTexture(srcVideo);
      videoTexture.colorSpace = SRGBColorSpace;
    }

    const videoMappedMaterial = new MeshBasicMaterial({
      map: videoTexture,
    });
    this.screen = new Mesh(screenGeometry, videoMappedMaterial);
    this.screen.position.set(0, 2, 15);
    this.screen.rotateY(Math.PI);
    this.defaultCamera.lookAt(this.screen.position);
  }

  loadCinemaTexture() {
    return new Promise((resolve, reject) => {
      new RGBELoader().load("/cinema_hall_4k.hdr", function (texture) {
        texture.mapping = EquirectangularReflectionMapping;
        if (texture !== null && texture !== undefined) {
          resolve(texture);
        } else {
          reject(Error("Promise rejected"));
        }
      });
    });
  }

  loadTextureUsingSphere({ imagePath }) {
    let hdrTexture = new RGBELoader().load(imagePath);
    let sphereGeometry = new SphereGeometry(65, 40, 30);
    let sphereMaterial = new MeshBasicMaterial({
      map: hdrTexture,
    });

    sphereMaterial.side = BackSide;
    let sphereMesh = new Mesh(sphereGeometry, sphereMaterial);
    sphereMesh.rotation.y = 3.1;
    this.environmentSphere = sphereMesh;
    this.scene.add(this.environmentSphere);
  }

  runAnimator() {
    this.getDefaultCamera().updateWorldMatrix();
    this.stereoCamera.update(this.getDefaultCamera());

    const size = new Vector2();
    this.getRenderer().getSize(size);

    this.getRenderer().setScissorTest(true);
    this.getRenderer().setScissor(0, 0, size.width / 2, size.height);
    this.getRenderer().setViewport(0, 0, size.width / 2, size.height);
    this.getRenderer().render(this.getScene(), this.stereoCamera.cameraR);
    this.getRenderer().setScissor(
      size.width / 2,
      0,
      size.width / 2,
      size.height
    );
    this.getRenderer().setViewport(
      size.width / 2,
      0,
      size.width / 2,
      size.height
    );
    this.getRenderer().render(this.getScene(), this.stereoCamera.cameraL);
    this.getRenderer().setScissorTest(false);
  }

  getScene() {
    return this.scene;
  }

  getEnvironmentSphere() {
    return this.environmentSphere;
  }

  getRenderer() {
    return this.renderer;
  }

  getScreen() {
    return this.screen;
  }

  getDefaultCamera() {
    return this.defaultCamera;
  }

  getOrbitController() {
    return this.orbitControl;
  }
}
