import { useState, useContext, createContext } from "react";

const StreamContext = createContext();

export function useStream() {
  return useContext(StreamContext);
}

export function StreamProvider({ children }) {
  const stream = useState(null);

  return (
    <StreamContext.Provider value={stream}>{children}</StreamContext.Provider>
  );
}
