import React from "react";
import TextInput from "../../layout/TextInput.js";
import isEmailAddress from "../../utils/isEmailAddress.js";
import { registerUser } from "../../actions/auth.actions.js";
import { useApiResponseContext } from "../../context/ApiResponseContext.js";
import Alert from "../../layout/Alert.js";

function Register() {
  const [values, setValues] = React.useState({
    emailAddress: "",
    emailAddressErr: false,
    emailAddressErrText: "",

    password: "",
    passwordErr: false,
    passwordErrText: "",

    confirmPassword: "",
    confirmPasswordErr: false,
    confirmPasswordErrText: "",

    showPassword: false,

    errors: {},
  });

  const [apiResponse, setApiResponse] = useApiResponseContext();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const inputHandler = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const resetInputValidationErrors = () => {
    setValues({
      ...values,
      emailAddressErr: false,
      emailAddressErrText: "",
      passwordErr: false,
      passwordErrText: "",
      confirmPasswordErr: false,
      confirmPasswordErrText: "",
    });
  };

  const isInputEmpty = (inputString) => {
    if (inputString === "") {
      return true;
    }
    return false;
  };

  const isUserInputValid = () => {
    resetInputValidationErrors();
    const isInvalidEmail = !isEmailAddress(values.emailAddress);
    const isPasswordEmpty = isInputEmpty(values.password);
    const isConfirmPasswordMismatch =
      values.password.trim() !== values.confirmPassword.trim();

    setValues({
      ...values,
      emailAddressErr: isInvalidEmail,
      emailAddressErrText: isInvalidEmail
        ? "Email address format is incorrect"
        : "",
      passwordErr: isPasswordEmpty,
      passwordErrText: isPasswordEmpty ? "Password cannot be empty" : "",
      confirmPasswordErr: isConfirmPasswordMismatch,
      confirmPasswordErrText: isConfirmPasswordMismatch
        ? "Confirm password does not match"
        : "",
    });

    return !isInvalidEmail && !isPasswordEmpty && !isConfirmPasswordMismatch;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isUserInputValid()) {
      const registerUserObject = {
        email_address: values.emailAddress.trim(),
        password: values.password.trim(),
        confirmPassword: values.confirmPassword.trim(),
      };
      handleRegister(registerUserObject);
    }
  };

  const handleRegister = async (registerUserObject) => {
    const response = await registerUser(registerUserObject);
    if (!response) {
      console.log("NO RESPONSE FROM SERVER");
      return;
    }
    setApiResponse(response.data);

    if (response.status === 200) {
      setTimeout(() => {
        window.location.href = "/user/login";
      }, 2000);
    }

    if (response.status === 500) {
      console.log(response);
    }
  };

  return (
    <div>
      <h1 style={{ padding: 20 }}>Register</h1>
      {apiResponse.message && <Alert text={apiResponse.message} />}
      {apiResponse.password && (
        <Alert type="error" text={apiResponse.password} />
      )}
      {apiResponse.passwordConstraint && (
        <Alert type="error" text={apiResponse.passwordConstraint} />
      )}
      {apiResponse.passwordMatch && (
        <Alert type="error" text={apiResponse.passwordMatch} />
      )}
      {apiResponse.emailExists && (
        <Alert type="error" text={apiResponse.emailExists} />
      )}
      <form action="#">
        <div>
          <TextInput
            id="emailAddress"
            placeholder="E-mail address"
            isError={values.emailAddressErr}
            errorText={values.emailAddressErrText}
            onChange={inputHandler}
          />
        </div>
        <div style={{ display: "inline-flex", padding: 20 }}>
          <TextInput
            id="password"
            type={values.showPassword ? "text" : "password"}
            placeholder="Password"
            isError={values.passwordErr}
            errorText={values.passwordErrText}
            onChange={inputHandler}
          />
          <i
            onClick={handleClickShowPassword}
            style={{ marginTop: 30 }}
            class="fa fa-eye"
          ></i>
          <TextInput
            id="confirmPassword"
            type={values.showPassword ? "text" : "password"}
            placeholder="Confirm password"
            isError={values.confirmPasswordErr}
            errorText={values.confirmPasswordErrText}
            onChange={inputHandler}
          />
        </div>
        <div>
          <input onClick={submitHandler} type="submit" value="Register"></input>
        </div>
      </form>
    </div>
  );
}
export default Register;
