import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import "./view.css";
import { useStream } from "../../context/StreamContext";
import { useNavigate } from "react-router-dom";
import ViewSelectionButton from "./styling/ViewSelectionButton";
import { isPhone } from "./3dViews/viewHelper";
import { createRTCClient } from "../RtcClientSdk";

function ViewSession() {
  const [stream, setStream] = useStream();
  const [me, setMe] = useState("");
  const [connectionInfo, setConnectionInfo] = useState({
    caller: "",
    callerSignal: null,
  });
  const [callAccepted, setCallAccepted] = useState(false);
  const [sessionInfoUI, setSessionInfoUI] = useState();
  const connectionRef = useRef({});
  const clientSocketRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    async function initRtcClient() {
      const client = await createRTCClient(me, null, 123);
      client.on("user-published", (userStream, mediaType) => {
        handleUserPublished(userStream, mediaType, client);
      });
    }
    if (process.env.REACT_APP_USE_AGORA_SDK === "true" && me !== "") {
      initRtcClient();
    }

    async function handleUserPublished(userStreamInfo, mediaType, client) {
      await client.subscribe(userStreamInfo, mediaType);
      console.log("Stream aquired:", userStreamInfo);
      setStream(userStreamInfo);
    }
  }, [me, setStream]);

  useEffect(() => {
    const isMobileDevice = isPhone({ defaultValue: true });
    if (!isMobileDevice) {
      navigate("/");
    }

    if (isMobileDevice && !stream) {
      clientSocketRef.current = io.connect(
        process.env.REACT_APP_BE_HOST + ":" + process.env.REACT_APP_BE_PORT
      );

      clientSocketRef.current.on("connect_error", () => {
        //TODO: Go to some error page
      });

      clientSocketRef.current.on("error", (err) => {
        console.log("Server socket error:", err);
      });

      clientSocketRef.current.on("me", (id) => {
        setMe(id);
        window.scrollTo(0, 0);
      });

      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        clientSocketRef.current.on("req-session", (data) => {
          console.log(data.signal);
          setConnectionInfo({
            caller: data.from,
            callerSignal: data.signal,
          });
        });
      }
    }
    return () => {
      clientSocketRef.current.disconnect();
    };
  }, [stream, navigate]);

  useEffect(() => {
    function acceptCall() {
      setCallAccepted(true);
      setSessionInfoUI(null);
      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        const peer = new Peer({
          initiator: false,
          trickle: false,
        });

        peer.on("signal", (data) => {
          console.log(data);
          clientSocketRef.current.emit("acceptCall", {
            signal: data,
            to: connectionInfo.caller,
          });
        });

        peer.on("stream", (stream) => {
          setSessionInfoUI(<h4>Loading...</h4>);
          setTimeout(() => {
            setStream(stream);
            setSessionInfoUI(null);
          }, 5000);
        });

        peer.on("error", (err) => {
          console.log("P2P connection error details:", err);
          clientSocketRef.current.emit("P2PConnectionError", {
            error: err,
            to: connectionInfo.caller,
          });
        });

        if (connectionInfo.callerSignal !== undefined) {
          peer.signal(connectionInfo.callerSignal);
        }

        connectionRef.current = peer;
      }
    }

    function denyCall() {
      clientSocketRef.current.emit("denyCall", {
        to: connectionInfo.caller,
      });
      setConnectionInfo((state) => ({ ...state, callerSignal: undefined }));
    }

    var connectionTimeout;
    if (connectionInfo.callerSignal && !callAccepted) {
      connectionTimeout = setTimeout(() => {
        setSessionInfoUI(<h4>Establishing connection</h4>);
        setTimeout(() => {
          setSessionInfoUI(<h4>Loading UI</h4>);
          setTimeout(() => {
            setSessionInfoUI(<h4>Obtaining stream</h4>);
            setTimeout(() => {
              setSessionInfoUI(
                <>
                  <h4>Session requeset recieved. Would you like to accept? </h4>
                  <br />
                  <a className="buttonPrimary" onClick={acceptCall}>
                    Accept
                  </a>
                  <a className="buttonRed" onClick={denyCall}>
                    Deny
                  </a>
                </>
              );
            }, 15000);
          }, 1000);
        }, 2000);
      }, 1000);
    }

    return () => {
      clearTimeout(connectionTimeout);
    };
  }, [connectionInfo, callAccepted, setStream, navigate]);

  const handleNavigationToCinema = () => {
    navigate("/view/cinema", { state: { redirect: true } });
  };

  const getPairingPin = () => {
    if (!!me) {
      return me;
    }
    return <i className="spinner fas fa-circle-notch"></i>;
  };

  return (
    <>
      {!stream ? (
        <h1>My Pairing Pin: {getPairingPin()}</h1>
      ) : (
        <h1>Please select viewer</h1>
      )}
      <br />
      <div className="flexWrapper">
        {sessionInfoUI && (
          <>
            <div style={{ paddingTop: "15px" }}>{sessionInfoUI}</div>
            <h1>
              <i className="spinner fa fa-circle-notch"></i>
            </h1>
          </>
        )}
        {!!stream && (
          <ViewSelectionButton
            Title={"Cinema"}
            selectionHandler={handleNavigationToCinema}
            imageSource={"/cinema.jpg"}
          />
        )}
      </div>
    </>
  );
}

export default ViewSession;
