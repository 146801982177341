import axios from "axios";

export const registerUser = async (userData) => {
  try {
    const res = await axios.post(
      "http://localhost:5010/auth/register",
      userData
    );
    return res;
  } catch (err) {
    return err.response;
  }
};
