import React from "react";

export default function ErrorBoundary() {
  return (
    <>
      <br />
      <h1>:(</h1>
      <br />
      <h1>Sorry, something went wrong!</h1>
    </>
  );
}
