import React, { Component } from "react";
import "./layout.css";

export default class Navigation extends Component {
  render() {
    return (
      <div className="nav-wrapper">
        <div id="nav">
          <h2
            className="nav-link"
            onClick={() => {
              window.location = "/";
            }}
          >
            Home
          </h2>
          <br />
          <br />
          <h2 className="nav-link">Information</h2>
          <br />
          <br />
          <h2 className="nav-link">Contact</h2>
          <br />
          <br />
          <h2 className="nav-link">T&C</h2>
        </div>
        <div className="nav-icons">
          <h2>
            <i style={{ paddingLeft: 10 }} className="fa fa-home"></i>
          </h2>
          <br />
          <br />
          <h2>
            <i style={{ paddingLeft: 10 }} className="fas fa-info-circle"></i>
          </h2>
          <br />
          <br />
          <h2>
            <i className="far fa-address-book"></i>
          </h2>
          <br />
          <br />
          <h2>
            <i className="fas fa-signature"></i>
          </h2>
        </div>
      </div>
    );
  }
}
