import React from "react";
import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import "./../../App.css";
import PairingForm from "./PairingForm";
import { isPhone } from "../view-session/3dViews/viewHelper";
import { createRTCClient, getMyVideoStreamTrack } from "../RtcClientSdk";
function Home() {
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [callDenied, setCallDenied] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const clientSocketRef = useRef();
  const connectionRef = useRef("");

  useEffect(() => {
    if (!isPhone({ defaultValue: false })) {
      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        clientSocketRef.current = io.connect(
          process.env.REACT_APP_BE_HOST + ":" + process.env.REACT_APP_BE_PORT
        );

        clientSocketRef.current.on("me", (id) => {
          setMe(id);
        });

        clientSocketRef.current.on("P2PErrorDetails", (errorDetails) => {
          alert(`P2P connection failure. \n ${errorDetails.code}`);
          console.log("P2P error details:", errorDetails);
          setCallAccepted(false);
          setStream(null);
        });

        clientSocketRef.current.on("connect_error", () => {
          alert(
            "Unable to establish connection to server.\nContact admin if error persists."
          );
        });
      }
    } else {
      window.location.href = "/view";
    }
  }, []);

  const establishVideoStreamUsingStreamClient = async () => {
    const videoStreamTrack = await getMyVideoStreamTrack().catch((err) => {
      console.log("Agora camera access error:", err);
    });
    setStream(videoStreamTrack);
  };

  const establishVideoStream = async () => {
    const mediaStreamOptions = {
      video: {
        frameRate: { ideal: 60 },
      },
      audio: false,
    };
    await navigator.mediaDevices
      .getDisplayMedia(mediaStreamOptions)
      .then((stream) => {
        setStream(stream);
      })
      .catch((err) => {
        alert("Error retrieving stream. Please check permissions");
        console.log("Stream error details:", err);
      });
  };

  const callUser = (id) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: stream,
    });

    peer.on("signal", (data) => {
      clientSocketRef.current.emit("req-session", {
        userToCall: id,
        signalData: data,
        from: me,
      });
    });

    peer.on("close", () => {
      setCallAccepted(false);
    });

    connectionRef.current = peer;

    clientSocketRef.current.on("callAccepted", (signal) => {
      setCallAccepted(true);
      connectionRef.current.signal(signal);
    });

    clientSocketRef.current.on("callDenied", () => {
      setCallDenied(true);
    });
  };
  const diconnect = () => {
    console.log(stream.active);
    setCallAccepted(false);
    setStream(undefined);
    connectionRef.current.destroy();
    window.location.reload();
  };

  const sendPairingRequest = async () => {
    if (!stream) {
      setErrorMessage("No video stream found!");
      return { result: false };
    }

    if (idToCall === "") {
      setErrorMessage("Please enter a pairing pin");
      return { result: false };
    }

    setErrorMessage(null);

    if (process.env.REACT_APP_USE_AGORA_SDK === "true") {
      createRTCClient(idToCall, null, 321).then(async (client) => {
        await client.publish([stream]);
      });
    } else {
      callUser(idToCall);
      setCallDenied(false);
    }
  };

  const establishStreamForm = (
    <div style={{ display: "inline-flex" }}>
      <h1>Video Stream Not Found!</h1>
      <a
        style={{ marginLeft: 20 }}
        className="buttonGreen"
        onClick={
          process.env.REACT_APP_USE_AGORA_SDK === "true"
            ? establishVideoStreamUsingStreamClient
            : establishVideoStream
        }
      >
        Start
      </a>
    </div>
  );

  return (
    <div style={{ marginTop: 30 }}>
      {stream ? (
        <h1>
          Stream OK{" "}
          <i style={{ paddingLeft: 30 }} className="fas fa-stream"></i>
        </h1>
      ) : (
        establishStreamForm
      )}
      <br />
      {!callAccepted ? (
        <PairingForm
          idToCall={idToCall}
          isCallDenied={callDenied}
          setIdToCall={setIdToCall}
          sendPairingRequest={sendPairingRequest}
        />
      ) : (
        <>
          <h1>
            Viewer Connected{" "}
            <i style={{ paddingLeft: 30 }} className="fas fa-user-check"></i>
          </h1>
          <br />
          <a className="buttonRed" onClick={diconnect}>
            Disconnect
          </a>
        </>
      )}
      <br />
      {errorMessage !== "" ? (
        <h2 className="errorText">{errorMessage}</h2>
      ) : null}
    </div>
  );
}

export default Home;
