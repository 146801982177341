import React from "react";

function TextInput({
  id,
  placeholder,
  isError = false,
  errorText,
  onChange,
  type = "text",
}) {
  return (
    <div style={{ display: "block" }}>
      <input
        type={type}
        className={isError ? "error-text" : ""}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
      ></input>
      {isError ? <h6 className="error-text">{errorText}</h6> : null}
    </div>
  );
}

export default TextInput;
