import React from "react";
import "./styling.css";

export default function ViewSelectionButton({
  Title,
  selectionHandler,
  imageSource,
}) {
  return (
    <div className="selectionContainer">
      <button className="selectionButton" onClick={selectionHandler}>
        <img alt="" className="selectionImage" src={imageSource}></img>
        <h1>{Title}</h1>
      </button>
    </div>
  );
}
