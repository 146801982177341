import React, { useState } from "react";

export default function PairingForm({
  idToCall,
  isCallDenied,
  setIdToCall,
  sendPairingRequest,
}) {
  const [pairingRequestSent, setPairingRequestSent] = useState(false);
  const showInfo = () => {
    console.log("Test Info");
  };

  const initPairingRequest = async () => {
    if (!pairingRequestSent) {
      setPairingRequestSent(true);
      const paringRequest = await sendPairingRequest();
      if (paringRequest && !paringRequest.result) {
        setPairingRequestSent(false);
      }
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginTop: 50 }}>
        <input
          placeholder="Pin"
          onChange={(e) => {
            setIdToCall(e.target.value);
          }}
          value={idToCall}
        ></input>
        <label onClick={showInfo} className="info-flip">
          i
        </label>
        <br />
        <br />
        <a
          href="#"
          className="buttonPrimary bouncy"
          onClick={initPairingRequest}
        >
          Pair
        </a>
      </div>
      <br />
      {isCallDenied && (
        <h5 style={{ color: "#c90000" }}>
          Pairing request has been denied by user!
        </h5>
      )}
    </div>
  );
}
