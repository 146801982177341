import { useState, useContext, createContext } from "react";

const ApiResponseContext = createContext();

export function useApiResponseContext() {
  return useContext(ApiResponseContext);
}

export function ApiResponseProvider({ children }) {
  const apiResponse = useState({});

  return (
    <ApiResponseContext.Provider value={apiResponse}>
      {children}
    </ApiResponseContext.Provider>
  );
}
